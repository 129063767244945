import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Header, Segment } from 'semantic-ui-react';

const TopPage = () => {
  return (
    <Segment inverted textAlign="center" vertical className="masthead">
      <Container text>
        <Header as="h2" inverted>
          クーポンサービス管理画面
        </Header>
        <Fragment>
          <Button.Group vertical>
            <Button as={Link} to="/issuedDashboard" size="medium" inverted>
              クーポン発券状況
            </Button>
            <Button as={Link} to="/bookedDashboard" size="medium" inverted>
              クーポン着券状況
            </Button>
          </Button.Group>
        </Fragment>
      </Container>
    </Segment>
  );
};

export default TopPage;
