import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Header, Segment, Statistic } from 'semantic-ui-react';

import { RootStoreContext } from '../../app/stores/rootStore';

const BookingChart: React.FC = () => {
  const rootStore = useContext(RootStoreContext);

  const { bookedData } = rootStore.bookingStore;

  return (
    <>
      <Segment.Group>
        <Segment.Group horizontal>
          <Segment style={{ width: 200, backgroundColor: 'white' }}>
            <Header as="h4">着券数の合計</Header>
            <Statistic
              horizontal
              size="huge"
              label="枚"
              value={bookedData.reduce((sum, data) => sum + data.count, 0).toLocaleString()}
            />
          </Segment>
          <Segment style={{ backgroundColor: 'white' }}>
            <Header as="h4">クーポン着券状況</Header>
            <ResponsiveContainer width="100%" height={400}>
              <ComposedChart
                width={1200}
                height={400}
                data={bookedData}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="date" scale="band" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar name="着券枚数" dataKey="count" fill="#ffc658" />
              </ComposedChart>
            </ResponsiveContainer>
          </Segment>
        </Segment.Group>
      </Segment.Group>
    </>
  );
};

export default observer(BookingChart);
