import { observer } from 'mobx-react-lite';
import React, { FormEvent, useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { Button, Form, Header, Icon, Segment } from 'semantic-ui-react';

import CustomInput from '../../app/common/form/CustomInput';
import { addMonth } from '../../app/common/util/util';
import { BookingFiltersFormValues } from '../../app/models/booking';
import { RootStoreContext } from '../../app/stores/rootStore';

const BookingDetailsFilter: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { bookingFilters, setBookingFilters, loadBookings } = rootStore.bookingStore;
  const { couponData, loadCoupons } = rootStore.couponStore;

  const [formValues, setFormValues] = useState(new BookingFiltersFormValues());

  useEffect(() => {
    if (bookingFilters !== null) {
      setFormValues(new BookingFiltersFormValues(bookingFilters));
    }
    loadCoupons();
  }, [bookingFilters, loadCoupons]);

  const handleSubmit = () => {
    // Validation
    if (!formValues.createdAtFrom || !formValues.createdAtTo) {
      toast.error('着券日は必須入力です。');
      return;
    }
    setBookingFilters(formValues);
    loadBookings();
  };

  const handleClear = () => {
    setBookingFilters(new BookingFiltersFormValues());
  };

  const handleDatePickerChange = (name: string, date: any) => {
    date && setFormValues({ ...formValues, [name]: date.toISOString() });
  };

  const handleInputChange = (event: FormEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;
    setFormValues({ ...formValues, couponId: value });
  };

  return (
    <>
      <Segment clearing>
        <Header icon="search" content="クーポン着券状況" />
        <p>※検索範囲は最大3か月が設定可能です。</p>
        <Form size="small" onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Field required>
              <label>着券日（From）</label>
              <DatePicker
                locale="ja"
                dateFormat="yyyy-MM-dd"
                selected={formValues.createdAtFrom ? new Date(formValues.createdAtFrom) : undefined}
                onChange={(date) => handleDatePickerChange('createdAtFrom', date)}
                customInput={<CustomInput />}
                minDate={addMonth(new Date(formValues.createdAtTo!), -3)}
                maxDate={new Date(formValues.createdAtTo!)}
              />
            </Form.Field>
            <label>～</label>
            <Form.Field required>
              <label>（To）</label>
              <DatePicker
                locale="ja"
                dateFormat="yyyy-MM-dd"
                selected={formValues.createdAtTo ? new Date(formValues.createdAtTo!) : undefined}
                onChange={(date) => handleDatePickerChange('createdAtTo', date)}
                customInput={<CustomInput />}
                minDate={new Date(formValues.createdAtFrom!)}
                maxDate={addMonth(new Date(formValues.createdAtFrom!), 3)}
              />
            </Form.Field>
            <Form.Field
              required
              label="クーポン種別"
              control="select"
              value={formValues.couponId}
              onChange={handleInputChange}
            >
              <option value=""></option>
              {couponData.map((coupon) => (
                <option key={coupon.id} value={coupon.id}>
                  {coupon.name}
                </option>
              ))}
            </Form.Field>
          </Form.Group>
          <Button primary floated="right" type="submit" icon labelPosition="left">
            検索
            <Icon name="search" />
          </Button>
        </Form>
        <Button secondary floated="right" icon labelPosition="left" onClick={() => handleClear()}>
          クリア
          <Icon name="undo" />
        </Button>
      </Segment>
    </>
  );
};

export default observer(BookingDetailsFilter);
