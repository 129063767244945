import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';

import LoadingComponent from '../../app/layout/LoadingComponent';
import { RootStoreContext } from '../../app/stores/rootStore';
import TransactionChart from './TransactionChart';
import TransactionFilters from './TransactionFilters';

const TransactionDashboard: React.FC = () => {
  const rootStore = useContext(RootStoreContext);
  const { isLoading, isLoaded } = rootStore.transactionStore;

  if (isLoading) return <LoadingComponent content="Loading..." />;

  return (
    <Grid>
      <Grid.Column width={8}>
        <TransactionFilters />
      </Grid.Column>
      {/* <Grid.Column width={16}>{isLoaded && <TransactionCsvDownload />}</Grid.Column> */}
      <Grid.Column width={16}>{isLoaded && <TransactionChart />}</Grid.Column>
    </Grid>
  );
};

export default observer(TransactionDashboard);
