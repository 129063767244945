import { addMonths, format, startOfMonth, subDays, subMonths } from 'date-fns';
import ja from 'date-fns/locale/ja';

export const addMonth = (date: Date, addCount: number) => {
  return addMonths(date, addCount);
};

export const getLastMonthStartDay = () => {
  const today = new Date();
  return format(new Date(subMonths(startOfMonth(today), +1)), 'yyyy-MM-dd', {
    locale: ja,
  });
};

export const getLastMonthEndDay = () => {
  const today = new Date();
  return format(new Date(subDays(startOfMonth(today), +1)), 'yyyy-MM-dd', {
    locale: ja,
  });
};

export const formatGraphDisp = (date: Date) => {
  return format(new Date(date), 'M/d', {
    locale: ja,
  });
};
