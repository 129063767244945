import { graphqlOperation } from 'aws-amplify';
import { makeAutoObservable } from 'mobx';

import API from '@aws-amplify/api';

import { ListCouponQuery } from '../../api/adminAPI';
import { listCoupon } from '../../graphql/queries';
import { RootStore } from './rootStore';

export default class CouponStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  couponData: any[] = [];
  isLoading = false;
  isLoaded = false;

  ascCouponSort(a: number, b: number): number {
    return a < b ? -1 : a === b ? 0 : 1;
  }

  ascCouponType(a: string, b: string): number {
    return a < b ? -1 : a === b ? 0 : 1;
  }

  loadCoupons = async () => {
    this.isLoading = true;
    try {
      let items: any[] = [];
      let nextToken = undefined;
      for (;;) {
        const result = await API.graphql(
          graphqlOperation(listCoupon, {
            nextToken,
          }),
        );
        if ('data' in result && result.data) {
          const couponData = result.data as ListCouponQuery;
          if (couponData.listCoupon) {
            couponData.listCoupon.items.forEach((item) =>
              items.push({
                id: item?.id,
                name: item?.name,
                sort: item?.sort,
                type: item?.type,
              }),
            );
            if (!couponData.listCoupon.nextToken) {
              this.couponData = items.sort((a, b) => {
                const score1 = this.ascCouponType(a.type, b.type);
                const score2 = this.ascCouponSort(a.sort, b.sort);
                return score1 * 10 + score2;
              });
              this.isLoading = false;
              this.isLoaded = true;
              break;
            }
            nextToken = couponData.listCoupon.nextToken;
          }
        }
      }
    } catch (error) {
      this.isLoading = false;
      console.log(error);
    }
  };
}
