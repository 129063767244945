export interface ITransactionFilters {
  createdAtFrom: string;
  createdAtTo: string;
}

export class TransactionFiltersFormValues implements ITransactionFilters {
  createdAtFrom: string = '';
  createdAtTo: string = '';

  constructor(init?: ITransactionFilters) {
    Object.assign(this, init);
  }
}
