import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Menu } from 'semantic-ui-react';

import { AmplifySignOut } from '@aws-amplify/ui-react';

const NavBar: React.FC = () => {
  return (
    <Menu fixed="top" inverted>
      <Container>
        <Menu.Item header exact name="Top" as={NavLink} to="/" />
        <Menu.Item name="クーポン発券状況" as={NavLink} to="/issuedDashboard" />
        <Menu.Item name="クーポン着券状況" as={NavLink} to="/bookedDashboard" />
        <Menu.Item position="right">
          <AmplifySignOut />
        </Menu.Item>
      </Container>
    </Menu>
  );
};

export default NavBar;
