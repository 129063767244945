import { Fragment } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Container } from 'semantic-ui-react';
import BookingDashboard from '../../features/bookings/BookingDashboard';

import NavBar from '../../features/nav/NavBar';
import TopPage from '../../features/top/TopPage';
import TransactionDashboard from '../../features/transactions/TransactionDashboard';
import NotFound from './NotFound';

const App: React.FC = () => {
  return (
    <>
      <ToastContainer position="bottom-right" />
      <Route exact path="/" component={TopPage} />
      <Route
        path={'/(.+)'}
        render={() => (
          <Fragment>
            <NavBar />
            <Container style={{ marginTop: '7em' }}>
              <Switch>
                <Route exact path="/issuedDashboard" component={TransactionDashboard} />
                <Route exact path="/bookedDashboard" component={BookingDashboard} />
                <Route component={NotFound} />
              </Switch>
            </Container>
          </Fragment>
        )}
      />
    </>
  );
};

export default withRouter(App);
