import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Header, Segment, Statistic } from 'semantic-ui-react';

import { RootStoreContext } from '../../app/stores/rootStore';

const TransactionChart: React.FC = () => {
  const rootStore = useContext(RootStoreContext);

  const { issuedData, couponData, mediaData } = rootStore.transactionStore;

  const COLORS1 = ['#9597e4', '#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c', '#d0ed57', '#ffc658'];
  const COLORS2 = ['#254278', '#006bbe', '#7ec8ef', '#ede8e4', '#96abbd', '#e9e9e9', '#c5bfb9'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: {
    cx: any;
    cy: any;
    midAngle: any;
    innerRadius: any;
    outerRadius: any;
    percent: any;
    index: any;
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <>
      <Segment.Group>
        <Segment.Group horizontal>
          <Segment style={{ width: 400, backgroundColor: 'white' }}>
            <Header as="h4">発券数の合計</Header>
            <Statistic
              horizontal
              size="huge"
              label="枚"
              value={issuedData.reduce((sum, data) => sum + data.trn_count, 0).toLocaleString()}
            />
          </Segment>
          <Segment style={{ width: 400, backgroundColor: 'white' }}>
            <Header as="h4">着券数の合計</Header>
            <Statistic
              horizontal
              size="huge"
              label="枚"
              value={issuedData.reduce((sum, data) => sum + data.bkn_count, 0).toLocaleString()}
            />
          </Segment>
          <Segment style={{ width: 400, backgroundColor: 'white' }}>
            <Header as="h4">クーポン種別</Header>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart width={400} height={400}>
                <Pie
                  dataKey="count"
                  nameKey="couponName"
                  data={couponData}
                  cx="50%"
                  cy="50%"
                  fill="#8884d8"
                  label={renderCustomizedLabel}
                  labelLine={false}
                >
                  {couponData.map((_entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS1[index % COLORS1.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Segment>
          <Segment style={{ width: 400, backgroundColor: 'white' }}>
            <Header as="h4">流入経路別</Header>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart width={400} height={400}>
                <Pie
                  dataKey="count"
                  nameKey="mediaCode"
                  data={mediaData}
                  cx="50%"
                  cy="50%"
                  fill="#8884d8"
                  label={renderCustomizedLabel}
                  labelLine={false}
                >
                  {mediaData.map((_entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Segment>
        </Segment.Group>
        <Segment>
          <Header as="h4">クーポン発券状況</Header>
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
              width={1200}
              height={400}
              data={issuedData}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="date" scale="band" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar name="発券枚数" dataKey="trn_count" fill="#8884d8" />
              <Line name="着券枚数" dataKey="bkn_count" stroke="#ff7300" type="monotone" />
            </ComposedChart>
          </ResponsiveContainer>
        </Segment>
      </Segment.Group>
    </>
  );
};

export default observer(TransactionChart);
