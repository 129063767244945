import { graphqlOperation } from 'aws-amplify';
import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';

import API from '@aws-amplify/api';

import {
  ListTransactionCouponCountQuery,
  ListTransactionDetailQuery,
  ListTransactionIssuedCountQuery,
  ListTransactionMediaCountQuery,
} from '../../api/adminAPI';
import { listTransactionDetail } from '../../graphql/queries';
import { formatGraphDisp } from '../common/util/util';
import { ITransactionFilters } from '../models/transaction';
import { RootStore } from './rootStore';

export default class TransactionStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  issuedData: any[] = [];
  couponData: any[] = [];
  mediaData: any[] = [];
  csvData: any[] = [];
  isLoading = false;
  isLoaded = false;
  isSubmitting = false;
  transactionFilters: ITransactionFilters | null = null;

  setTransactionFilters = (transactionFilters: ITransactionFilters) => {
    this.transactionFilters = transactionFilters;
  };

  loadTransactions = async () => {
    this.isLoading = true;
    try {
      const query = `query loadTransactions(
        $createdAtFrom: String!
        $createdAtTo: String!
      ) {
        listTransactionIssuedCount(
          createdAtFrom: $createdAtFrom
          createdAtTo: $createdAtTo
        ) {
          date
          trn_count
          bkn_count
        }
        listTransactionCouponCount(
          createdAtFrom: $createdAtFrom
          createdAtTo: $createdAtTo
        ) {
          couponName
          count
        }
        listTransactionMediaCount(
          createdAtFrom: $createdAtFrom
          createdAtTo: $createdAtTo
        ) {
          mediaCode
          count
        }
      }
`;
      const result = await API.graphql(
        graphqlOperation(query, {
          createdAtFrom: this.transactionFilters?.createdAtFrom,
          createdAtTo: this.transactionFilters?.createdAtTo,
        }),
      );
      if ('data' in result && result.data) {
        // クーポン発券枚数
        const issuedData = result.data as ListTransactionIssuedCountQuery;
        if (issuedData.listTransactionIssuedCount) {
          this.issuedData = issuedData.listTransactionIssuedCount;
          this.issuedData = issuedData.listTransactionIssuedCount.map((item) => ({
            date: item?.date ? formatGraphDisp(new Date(item.date!)) : '',
            trn_count: item?.trn_count,
            bkn_count: item?.bkn_count,
          }));
        } else {
          toast.warning('分析DBを起動していますので、少々お待ちください。（1分程度）');
        }
        // クーポン種別
        const couponData = result.data as ListTransactionCouponCountQuery;
        if (couponData.listTransactionCouponCount) {
          this.couponData = couponData.listTransactionCouponCount;
        }
        // 流入経路別
        const mediaData = result.data as ListTransactionMediaCountQuery;
        if (mediaData.listTransactionMediaCount) {
          this.mediaData = mediaData.listTransactionMediaCount;
        }
      }
      this.isLoading = false;
      this.isLoaded = true;
    } catch (error) {
      this.isLoading = false;
      console.log(error);
    }
  };

  loadCsvDataTransactions = async () => {
    this.isSubmitting = true;
    try {
      const result = await API.graphql(
        graphqlOperation(listTransactionDetail, {
          createdAtFrom: this.transactionFilters?.createdAtFrom,
          createdAtTo: this.transactionFilters?.createdAtTo,
        }),
      );
      if ('data' in result && result.data) {
        // CSVデータ
        const csvData = result.data as ListTransactionDetailQuery;
        if (csvData.listTransactionDetail) {
          this.csvData = csvData.listTransactionDetail.map((item) => ({
            id: item?.id,
            クーポン番号: item?.couponNo,
            広告コード: item?.mediaCode,
            クーポン名: item?.couponName,
            メールアドレス: item?.email,
            使用日時: item?.dateUsed,
            予約日時: item?.bookingTime,
            予約番号: item?.bookingNo,
            更新日時: item?.updatedAt,
            登録日時: item?.createdAt,
          }));
        } else {
          toast.warning('分析DBを起動していますので、少々お待ちください。（1分程度）');
        }
      }
      this.isSubmitting = false;
    } catch (error) {
      this.isSubmitting = false;
      console.log(error);
    }
  };
}
