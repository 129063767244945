import { createContext } from 'react';

import BookingStore from './bookingStore';
import CouponStore from './couponStore';
import TransactionStore from './transactionStore';

export class RootStore {
  transactionStore: TransactionStore;
  bookingStore: BookingStore;
  couponStore: CouponStore;

  constructor() {
    this.transactionStore = new TransactionStore(this);
    this.bookingStore = new BookingStore(this);
    this.couponStore = new CouponStore(this);
  }
}

export const RootStoreContext = createContext(new RootStore());
