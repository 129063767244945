import './app/layout/styles.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import Amplify from 'aws-amplify';
import ja from 'date-fns/locale/ja';
import { createBrowserHistory } from 'history';
import { registerLocale } from 'react-datepicker';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';

import App from './app/layout/App';
import ScrollToTop from './app/layout/ScrollToTop';
import config from './aws-exports';
import reportWebVitals from './reportWebVitals';

export const history = createBrowserHistory();

Amplify.configure(config);

registerLocale('ja', ja);

ReactDOM.render(
  <Router history={history}>
    <ScrollToTop>
      <AmplifyAuthenticator usernameAlias="email">
        <AmplifySignIn slot="sign-in" usernameAlias="email" hideSignUp />
        <App />
      </AmplifyAuthenticator>
    </ScrollToTop>
  </Router>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
