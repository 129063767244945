/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBooking = /* GraphQL */ `
  query GetBooking($transactionId: String!, $dateUsed: String!) {
    getBooking(transactionId: $transactionId, dateUsed: $dateUsed) {
      transactionId
      dateUsed
      bookingTime
      bookingNo
      event
      createdAt
      updatedAt
    }
  }
`;
export const listBooking = /* GraphQL */ `
  query ListBooking {
    listBooking {
      transactionId
      dateUsed
      bookingTime
      bookingNo
      event
      createdAt
      updatedAt
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: String!) {
    getTransaction(id: $id) {
      id
      couponNo
      pinCode
      mediaCode
      couponId
      couponName
      couponSort
      event
      email
      bookingTimes
      validPeriodStartingTime
      validPeriodEndingTime
      tokenId
      createdAt
      updatedAt
    }
  }
`;
export const listTransaction = /* GraphQL */ `
  query ListTransaction {
    listTransaction {
      id
      couponNo
      pinCode
      mediaCode
      couponId
      couponName
      couponSort
      event
      email
      bookingTimes
      validPeriodStartingTime
      validPeriodEndingTime
      tokenId
      createdAt
      updatedAt
    }
  }
`;
export const listTransactionDetail = /* GraphQL */ `
  query ListTransactionDetail($createdAtFrom: String!, $createdAtTo: String!) {
    listTransactionDetail(
      createdAtFrom: $createdAtFrom
      createdAtTo: $createdAtTo
    ) {
      id
      couponNo
      mediaCode
      couponName
      event
      email
      dateUsed
      bookingTime
      bookingNo
      createdAt
      updatedAt
    }
  }
`;
export const listTransactionIssuedCount = /* GraphQL */ `
  query ListTransactionIssuedCount(
    $createdAtFrom: String!
    $createdAtTo: String!
  ) {
    listTransactionIssuedCount(
      createdAtFrom: $createdAtFrom
      createdAtTo: $createdAtTo
    ) {
      date
      trn_count
      bkn_count
    }
  }
`;
export const listTransactionBookedCount = /* GraphQL */ `
  query ListTransactionBookedCount(
    $createdAtFrom: String!
    $createdAtTo: String!
    $couponId: String!
  ) {
    listTransactionBookedCount(
      createdAtFrom: $createdAtFrom
      createdAtTo: $createdAtTo
      couponId: $couponId
    ) {
      date
      count
    }
  }
`;
export const listTransactionMediaCount = /* GraphQL */ `
  query ListTransactionMediaCount(
    $createdAtFrom: String!
    $createdAtTo: String!
  ) {
    listTransactionMediaCount(
      createdAtFrom: $createdAtFrom
      createdAtTo: $createdAtTo
    ) {
      mediaCode
      count
    }
  }
`;
export const listTransactionCouponCount = /* GraphQL */ `
  query ListTransactionCouponCount(
    $createdAtFrom: String!
    $createdAtTo: String!
  ) {
    listTransactionCouponCount(
      createdAtFrom: $createdAtFrom
      createdAtTo: $createdAtTo
    ) {
      couponName
      count
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($event: String!, $id: String!) {
    getCoupon(event: $event, id: $id) {
      event
      id
      name
      sort
      type
      createdAt
      updatedAt
    }
  }
`;
export const listCoupon = /* GraphQL */ `
  query ListCoupon($count: Int, $nextToken: String) {
    listCoupon(count: $count, nextToken: $nextToken) {
      items {
        event
        id
        name
        sort
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCouponByKey = /* GraphQL */ `
  query ListCouponByKey(
    $event: String!
    $id: String
    $count: Int
    $nextToken: String
  ) {
    listCouponByKey(
      event: $event
      id: $id
      count: $count
      nextToken: $nextToken
    ) {
      items {
        event
        id
        name
        sort
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
