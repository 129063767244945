import { graphqlOperation } from 'aws-amplify';
import { makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';

import API from '@aws-amplify/api';

import { ListTransactionBookedCountQuery } from '../../api/adminAPI';
import { formatGraphDisp } from '../common/util/util';
import { IBookingFilters } from '../models/booking';
import { RootStore } from './rootStore';

export default class BookingStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  bookedData: any[] = [];
  isLoading = false;
  isLoaded = false;
  isSubmitting = false;
  bookingFilters: IBookingFilters | null = null;

  setBookingFilters = (bookingFilters: IBookingFilters) => {
    this.bookingFilters = bookingFilters;
  };

  loadBookings = async () => {
    this.isLoading = true;
    try {
      const query = `query loadBookings(
        $createdAtFrom: String!
        $createdAtTo: String!
        $couponId: String!
      ) {
        listTransactionBookedCount(
          createdAtFrom: $createdAtFrom
          createdAtTo: $createdAtTo
          couponId: $couponId
        ) {
          date
          count
        }
      }
`;
      const result = await API.graphql(
        graphqlOperation(query, {
          createdAtFrom: this.bookingFilters?.createdAtFrom,
          createdAtTo: this.bookingFilters?.createdAtTo,
          couponId: this.bookingFilters?.couponId,
        }),
      );
      if ('data' in result && result.data) {
        // クーポン着券枚数
        const bookedData = result.data as ListTransactionBookedCountQuery;
        if (bookedData.listTransactionBookedCount) {
          this.bookedData = bookedData.listTransactionBookedCount;
          this.bookedData = bookedData.listTransactionBookedCount.map((item) => ({
            date: item?.date ? formatGraphDisp(new Date(item.date!)) : '',
            count: item?.count,
          }));
        } else {
          toast.warning('分析DBを起動していますので、少々お待ちください。（1分程度）');
        }
      }
      this.isLoading = false;
      this.isLoaded = true;
    } catch (error) {
      this.isLoading = false;
      console.log(error);
    }
  };
}
